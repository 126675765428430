import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  lead: {
    margin: "0.5rem 0 1rem",
  },
}))

export default ({ pageTitle }) => {
  const classes = useStyles()
  return (
    <>
      <div className="container">
        <h1>{pageTitle}</h1>
        <div className={classes.lead}>
          リーダー：Ayana
          <br />
          果実酒、手作り味噌、山菜料理、ピザ、グルテンフリーパンなどの料理クラスや勉強会、試食会、クラフトビール研究など
        </div>
        <Link
          to="./member/group"
          style={{ color: "#497988", marginTop: "4rem", display: "block" }}
        >
          ←グループ一覧へ戻る
        </Link>
      </div>
    </>
  )
}
