import React from "react"
import Layout from "../../../components/member/Layout"
import Food from "../../../components/member/group/Food"

export default () => {
  const pageSlug = "food"
  const pageTitle = "食とお酒の研究部"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Food pageTitle={pageTitle} />
    </Layout>
  )
}
